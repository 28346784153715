// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-templates-about-js": () => import("./../../../src/templates/about.js" /* webpackChunkName: "component---src-templates-about-js" */),
  "component---src-templates-book-js": () => import("./../../../src/templates/book.js" /* webpackChunkName: "component---src-templates-book-js" */),
  "component---src-templates-classes-and-events-js": () => import("./../../../src/templates/classes-and-events.js" /* webpackChunkName: "component---src-templates-classes-and-events-js" */),
  "component---src-templates-event-js": () => import("./../../../src/templates/event.js" /* webpackChunkName: "component---src-templates-event-js" */),
  "component---src-templates-index-js": () => import("./../../../src/templates/index.js" /* webpackChunkName: "component---src-templates-index-js" */),
  "component---src-templates-practices-js": () => import("./../../../src/templates/practices.js" /* webpackChunkName: "component---src-templates-practices-js" */),
  "component---src-templates-school-js": () => import("./../../../src/templates/school.js" /* webpackChunkName: "component---src-templates-school-js" */),
  "component---src-templates-workshops-js": () => import("./../../../src/templates/workshops.js" /* webpackChunkName: "component---src-templates-workshops-js" */),
  "component---src-templates-yoga-therapy-js": () => import("./../../../src/templates/yoga-therapy.js" /* webpackChunkName: "component---src-templates-yoga-therapy-js" */)
}

